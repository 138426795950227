//$primary: red;
//$secondary: red;

//@include transition(all 5s ease-in 0.4s);

body {
  background-color: #f5f8fb !important;
  height: 100%;
}

.k-preloader {
  display: flex;
  align-items: center;
  position: fixed;
  left: 65px;
  top: 52px;
  width: 100%;
  z-index: 9999;
  height: 100vh;
  opacity: 1;

  .loader-element {
      @include transition(spin .3s linear infinite);
      -webkit-animation:spin 3s linear infinite;
      -moz-animation:spin 3s linear infinite;
      animation:spin 3s linear infinite;
  }

  &.loading {
    height: 100vh;
    opacity: 1;
    //@include transition(all 1s);
  }

  &.show {
      height: 0;
      opacity: 0;
      //@include transition(all 0.1s);
      
      .loader-element {
          display: none !important;
      }
  }

}


.noShowScroll {
  overflow: hidden;
}

.k-of-y-scroll {
  overflow-y: scroll;
}

.k-of-x-scroll {
  overflow-x: scroll;
}

.k-of-auto {
  overflow-x: auto;
}

.disp-flex {
  display: flex;
}


.content {
  background-color: #f5f8fb;
}

.k-txt-aln-e {
  text-align: end;
}

.col-2-5 {
  flex: 0 0 auto;
  width: 18%;
}

.col-9-5 {
  flex: 0 0 auto;
  width: 82%;
}

.mt-m2 {
  margin-top: -2px;
}

.mt-m10 {
  margin-top: -10px;
}

.ml-15 {
  margin-left: 15px;;
}

.ml-30 {
  margin-left: 30px;;
}

.ml-80 {
  margin-left: 80px;;
}

.ml-95 {
  margin-left: 95px;;
}

.mw-310px {
  max-width: 310px;
}

.hmh-100 {
  max-height: 100%;
  height: 100%;
}

.hmh-100vh-260px {
  height: calc(100vh - 260px);
  max-height: calc(100vh - 260px);
}

.hmh-100vh-270px {
  height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
}

.hmh-100vh-280px {
  height: calc(100vh - 280px);
  max-height: calc(100vh - 280px);
}

.hmh-100vh-290px {
  height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
}

.hmh-100vh-300px {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
}

.minh-400-mh-100vh {
  min-height: 400px;
  max-height: calc(100vh - 300px);
}

.minh-400 {
  min-height: 400px;
}

.height-100-PC {
  height: 100%;
}

.width-100-PC {
  width: 100%;
}

.width-90-PC {
  width: 90%;
}

.width-80-PC {
  width: 80%;
}

.width-60 {
  width: 60px;
}

.k-mmh-10 {
  min-height: 10px;
  max-height: 10px;
}

.k-mmh-15 {
  min-height: 15px;
  max-height: 15px;
}

.k-mmh-20 {
  min-height: 20px;
  max-height: 20px;
}

.k-mmh-50 {
  min-height: 50px;
  max-height: 50px;
}

.k-mmh-200 {
  min-height: 200px;
  max-height: 200px;  
}

.k-mmh-270 {
  min-height: 270px;
  max-height: 270px;  
}

.k-mmh-300 {
  min-height: 300px;
  max-height: 300px;  
}

.k-mh-40 {
  max-height: 40px;
}

.k-mh-60 {
  max-height: 60px;
}

.k-mh-200 {
  max-height: 200px;
}

.k-mmw-90 {
  min-width: 90px;
  max-width: 90px;
}

.k-mmw-250 {
  min-width: 250px;
  max-width: 250px;
}

.k-br-5 {
  border-radius: 5px;
  overflow: hidden;
}

.btn-no-shadow {
  box-shadow: none !important;
}


.k-ac-c {
  align-content: center!important;
}

.k-d-gr {
  display: grid;
}


.k-of-x-auto {
  overflow-x: auto;
}

.k-shadow {
  box-shadow: 0 .5rem 1rem rgba(46,54,80,.15)!important;
}

.form-control:focus {
  box-shadow:none
}

.form-control.k-inputText12::placeholder { 
  color: #6e757f;
  opacity: 1; /* Firefox */
}

.k-of-x-auto::-webkit-scrollbar {
  display: none;
}

.kuro-button-primary {
  background-color: #93bdff !important;
  border-radius: 0.2rem !important;
  border-style: none;
  box-shadow: 0 .5rem 1rem rgba(46,54,80,.15)!important;
}

.k-pt-1{
  padding-top: 1px!important;
}

.k-pt-2{
  padding-top: 2px;
}

.k-pb-2{
  padding-bottom: 2px;
}

.k-ml--10 {
  margin-left: -10px;
}

.k-fw-800 {
  font-weight: 800;
}

.k-fw-900 {
  font-weight: 900;
}

.k-brd-transparent {
  border-color: transparent;
}

.k-t-body-scroll-admin {
  //display: block;
  overflow: auto;
  height: 100%;
  max-height: 100%;
  width: 100%;
}

tr::hover{
  background:rgb(172, 172, 172);
}



.kuro-input-group {
  border-radius: .1rem;;
  transition: $transition-base;

  input {
    border-radius: 0.2rem !important;
    border-style: none;
    box-shadow: 0 .5rem 1rem rgba(46,54,80,.15)!important;
    // filter: drop-shadow(3, 3, 10, #707070) !important;
  }

  .form-control {
    // box-shadow: drop-shadow(3 3 10 #707070);
  
    &:not(:first-child) {
          border-left: 0;
          padding-left: 0;
    }
    &:not(:last-child) {
      border-right: 0;
      padding-right: 0;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.horizontalList {
  list-style-type: none;
  display: flex;
  overflow-x: scroll;
}

.horizontalList::-webkit-scrollbar {
  display: none;
}

.horizontalListItem {
  list-style-type: none;
}


.kuro-modal {
  width: 60%;
  max-width: none !important;
  // overflow-y: auto !important;
  overflow: auto !important;
  // padding-right: 0px !important;
  // margin-right: 0px !important;
}

.kuro-card {
  border-radius: 0.5rem;
}

.k-ctrlImg {
  max-width: 15px;
  max-height: 15px;
}

.k-fade {
  opacity: 0.2;
}

.kuro-nav-container {
  display: flex;
  width: 45px;
  background-color: #f5f8fb;
}

.sidebar .nav-item .nav-link:hover {
  color: #f5f8fb;
  background-color: #f5f8fb;
}

.user-avatar {
  height: 1.5rem;
  width: 1.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  border-radius: 1%;
}

.bg-primary {
  --bs-bg-opacity: 0;
  background-color: #f5f8fb;
}


.sidebar .nav-item .nav-link {
  color: #eaedf2;
  border: none;
}

.sidebar .nav-item.active > .nav-link {
  color: #eaedf2;
  background-color: #f5f8fb;
  border: none;
}

.sidebar {
  width: 65px;
  //@include media-breakpoint-up(md) {
  //  width: 100%;
  //  max-width: 260px;
  //}
}

.content {
  margin-left: 70px;
}

.card-status {
  --bs-card-border-width: 0px;
  border-radius: 10px;
  min-width: 190px;
  max-width: 190px;
}

.card-header-0{
  color: #414750;
  font-size:20px;
}

.card-header-1{
  color: #414750;
  font-size:18px;
}

.card-header-12{
  color: #414750;
  font-size:12px;
}

.card-header-13{
  color: #414750;
  font-size:13px;
}

.card-header-15{
  color: #414750;
  font-size:15px;
}

.card-subheader-0{
  color: #6e757f;
  font-size:15px;
}

.card-subheader-12{
  color: #6e757f;
  font-size:120px;
}

.card-subheader-10{
  color: #6e757f;
  font-size:10px;
}



.white-txt-10{
  color: white;
  font-size:10px;
}

.white-txt-15{
  color: white;
  font-size:15px;
}

.k-fp-bar-container {
  display: flex;
  align-items: center;
  height: 20px;
}

.k-fp-bar {
  height: 70%;
}

.black-txt-10{
  color: #000000;
  font-size:10px;
}

.card-subheader-12{
  color: #6e757f;
  font-size:12px;
}

.card-value-0{
  color: #0a4ba5;
  font-size:30px;
  text-align: center;
}

.project-scroll::-webkit-scrollbar {
  display: none;
} 

.new-project-card-sidebar {
  width: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px;
  font-size: 15px;
  border: none;
}

.new-project-card-subtitle {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 7px;
  margin-left: 5px;
}

.project-meta {
  font-size: 12px;
  color: #6e757f;
  text-align: right;
}

.npc-sidebar-yellow {
  background-color: #ffd480;
  color: #edf2f9;
}

.npc-sidebar-profile {
  background-color: #ffd480;
  color: #edf2f9;
}

.npc-sidebar-project {
  background-color: #7187ca;
  color: #edf2f9;
}

.npc-sidebar-product {
  background-color: #7187ca;
  color: #edf2f9;
}

.npc-sidebar-lightgrey {
  background-color: #d5d5d5;
  color: #edf2f9;
}



.new-project-card{
  border: none;
}

.no-border {
  border: none;
}

.nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}



.k-btn {
  border-radius: 5px;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.k-bl {
  border-left: 1px solid #eaedf2;
}

.k-br {
  border-right: 1px solid #eaedf2;
}

.k-bb {
  border-bottom: 1px solid #eaedf2;
}

.k-bt {
  border-top: 1px solid #eaedf2;
}



.k-headerText {
  font-size: 24px;
  color: rgb(0, 0, 0);
}

.k-subHeaderText {
  font-size: 15px;
  color: rgb(0, 0, 0);
  margin-top: -10px;
}

.k-inputHeader {
  font-size:18px;
}

.k-detailHeaderImg {
  min-height: 35px;
  max-height: 35px;
}

.k-iconImg {
  min-height: 200px;
  max-height: 200px;
}

.k-detailHeader {
  font-size:28px;
  margin-top: -5px;
  color: rgb(0, 0, 0);
}

.k-detailSubHeader {
  font-size:18px;
  margin-top: -5px;
  color: rgb(0, 0, 0);
}

.k-detailControlHeader {
  font-size:18px;
  color: rgb(0, 0, 0);
}

.k-inputText22 {
  font-size:22px;
  color: rgb(85, 85, 85);
}

.k-inputText20 {
  font-size:20px;
  color: rgb(85, 85, 85)
}

.k-inputText18 {
  font-size:18px;
  color: rgb(85, 85, 85)
}

.k-inputText15 {
  font-size:15px;
  color: rgb(85, 85, 85)
}

.k-inputText15-light {
  font-size:15px!important;
  color: rgb(172, 172, 172)!important
}

.k-inputText12 {
  font-size:12px!important;
  color: rgb(85, 85, 85)!important;
}

.k-inputText10 {
  font-size:10px;
  color: rgb(85, 85, 85);
}


.RUNNING, .STARTING {
  color: #8CBE82;
}

.STOPPING, .STOPPED {
  color: #FF8585;
}

.HIBERNATION, .HIBERNATING {
  color: #FFD47B;
}

.BUILDING {
  color: #ADAEB0
}

.k-inputSelect:focus,
.k-inputSelect:active,
.k-inputSelect {
  border:none;
  box-shadow:none;
  padding-left: 0px;
  padding-top: 0px;
  color: #000000;
  background-image: none;
  border-color: transparent;
}

.k-modalIcon {
  max-height: 45px;
}

.k-modalIconSM {
  max-height: 20px;
}

.k-inputText:disabled {
  background-color: #FFFF;
  color: #000000;
}s

.k-opacityLight {
  opacity: 0.3;
}

.k-side-nav-image {
  width: 15px!important;
  height: 15px!important;
}

.k-sidenav {
  padding: 0.1rem 0.1rem!important;
}

.k-inputText:focus,
.k-inputText:active,
.k-inputText {
  border:none;
  box-shadow:none;
  padding-left: 0px;
  padding-top: 0px;
  color: #000000;
  border-color: transparent;
}


input[type="text"]::-webkit-input-placeholder {
  color: #c1c2c4;
}

.disabled-text {
  color: #c1c2c4;
}

.fullScreenContainer {
  position: absolute;
  width: calc(100% - 100px);
  height: calc(100% - 210px);
  background-color: white;
}

.overlayBase {
  top: 24px;
  opacity: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: calc(100% - 24px);
  text-align: center;
  margin: 0px;
  padding: 0px;
  animation: showContentEase 0.5s ease 0.3s forwards;
  background-color: white;
}

.contentHeaderDropdown {
  top: 30px;
  width: 350px;
  left: calc(100% - 360px);
  background-color: #E8EAED;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 .5rem 1rem rgba(46,54,80,.15)!important;
  text-align: left;
}

.dropdownCol-newItem {
  text-align: end;
  margin-top: 5px;
  padding-right: 0px;
}

.dropdown-newItem {
  width: 15px;
  height: 15px;
  opacity: 0.4;
  
}

.dropdown-newItem:hover {
  opacity: 1;
}

.dropdown-title {
  color: #000000;
  font-size: 20px;;
}

.dropdownTitlleSubheader {
  color: #000000;
  font-size: 14px;;
}

.dropdown-tab-header {
  padding-bottom: 3px;
  padding-top: 0px;
  padding-right: 3px;
  padding-left: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-top: 0px;
}

.dropdown-tab-header.active {
  background-color: white;
}

.k-tabNav {
  margin-right: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-color: #f5f8fb;
  border-top-color: #f5f8fb;
  border-right-color: #f5f8fb;
  border-left-color: #f5f8fb;
  border-style: solid;
  border-width: 1px;
  margin-top: 0px;
  color: #dadbdc;
  z-index:0;
  position:relative;
  padding-left: 5px!important;
  padding-right: 5px!important;
  margin-left: -5px;
}

.k-tabNav.active {
  border-bottom-color: #f5f8fb;
  border-top-color: #dee1e3;
  border-right-color: #dee1e3;
  border-left-color: #dee1e3;
  border-style: solid;
  color: #000000;
  font-weight: 900;
  z-index:99;
  top: 1px;
}

.k-tabNav.hover {
  border-bottom-color: #f5f8fb;
  border-top-color: #e2e5e6;
  border-right-color: #e2e5e6;
  border-left-color: #e2e5e6;
  border-style: solid;
  color: #e2e5e6;
  //background-color: white;
}

.k-tabList {
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 0!important;
  padding-bottom: 0!important;
  margin-left: 1rem!important;
}

.k-navItem {
  margin-bottom: -1px;
}

.k-tabSubHeader{
  font-size:0px;
  z-index: -10;
}

.k-tabNav.active .k-tabSubHeader{
  color: #7b7b7b;
  font-size:14px;
  z-index: 10;
  //font-weight: 900;
}

.addIcon {
  transition: transform .7s ease-in-out;
}
.addIcon:hover {
  transform: rotate(360deg);
}

.tox-tinymce-inline{
  z-index:99999999999 !important;
}

.showDropdown {
  z-index: 11;
  animation: showEase 0.5s ease 0.3s forwards;
  min-width: 350px;
}

.dropdownContentHeader {
  min-height: 10px;
}

.dropdownContentRow {
  width: 314px;
  margin: 0px;
  padding: 0px;
  height: calc(100% - 0px);
  //height: 100%;
}

.dropdownContent {
  margin-left: 0px;
  margin-right: 2%;
  padding: 0px;
  width: 317px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  background-color: white;
  height: calc(100% - 10px);
  //height: 100%;
}

@keyframes flashAnimation {
  0% { background-color: transparent; }
  50% { background-color: #f99b9b}
  100% { background-color: transparent; }
}

.flash {
  animation: flashAnimation 1s infinite;
}

.scrolling-topcontainer{
  position: relative;
  height: calc(100% - 60px);
  height: calc(100% - 60px);
}

.scrolling-container {
  overflow-y: auto;
  height: 100%;
}

@keyframes showEase {
  from { opacity: 0; height: 0px;}
  to { opacity: 1;  height: calc(100% - 40px);}
}

.tabsHeader{
  background-color: #E8EAED;
  max-height: 25px;
}

.nav-tabs .nav-link.active {
  background-color: white;
  color: #000000;
}

.editorDiv {
  max-height: 100%;
}

.toolbar {
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #E8EAED;
  border-radius: 0.5em 0.5em 0em 0em;
}

.dropdown-tab {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #E8EAED;
  border-radius: 0.5em 0.5em 0em 0em;
}

.toobarButton {
  height: 15px;
}

.k-card-1 {
  max-height: 200px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.k-card-1::-webkit-scrollbar {
  display: none;
}

.k-card-2 {
  max-height: 230px;
  height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
}

.k-card-2::-webkit-scrollbar {
  display: none;
}

.k-card-3 {
  max-height: calc(100%);
  height: calc(100%);
  overflow-y: auto;
  overflow-x: hidden;
}

.k-card-3::-webkit-scrollbar {
  display: none;
}

.hideContent {
  //left: 80px;
  z-index: 11;
  animation: hideContentEase 0.5s ease 0.3s forwards;
}

@keyframes showContentEase {
  from { opacity: 1; }
  to { opacity: 0;  }
}

@keyframes hideContentEase {
  from { opacity: 0; }
  to { opacity: 1;  }
}

.button-card {
  border: none;
  min-width: 210px;
  max-width: 210px;
  max-height: 42px;
}

.dropdown-button-card {
  border: none;
}

.note-card {
  background-color: #ffffff;
}

.note-card:hover {
  background-color: #f9e39b;
}

.header-black-22{
  color: #000000;
  font-size:22px;
  font-weight: 900;
}

.subheader-black-14{
  color: #000000;
  font-size:14px;
  font-weight: 600;
}

.header-grey-22{
  color: #585858;
  font-size:22px;
  font-weight: 900;
}

.note-header-0{
  color: #000000;
  font-size:15px;
  font-weight: 900;
}

.note-subheader-0{
  color: #000000;
  font-size:15px;
  font-weight: 400;
}


.note-date-0{
  color: #000000;
  font-size:12px;
  font-weight: 500;
}

.note-body-0{
  color: #414750;
  font-size:12px;
  font-weight: 300;
}

.note-body-1{
  color: #414750;
  font-size:12px;
  font-weight: 700;
}

.password-details-1{
  color: #000000;
  font-size:14px;
  font-weight: 500;
}

.password-details-0{
  color: #414750;
  font-size:14px;
  font-weight: 300;
}

.otp-countdown {
  animation: pulse 1s infinite;
  border-radius: 50%;
  border: 0.5px solid;
  width: 17px;
  height: 17px;
  text-align: center;
  background-color: #ffffff;
  display: flex;
  font-size:11px;
  font-weight: 300;
  color: #414750;
  margin-top: 3px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.accordion-button-notes {
  border: none;
  text-align: left;
  background-color: white;
}

.accordion-collapse-notes {
  padding-left: 10px;
}

.delete-note:hover {
  opacity: 1;
}

.dropdown-tab-icon {
  height: 15px;
  width: 15px;
}

.k-iFrame {
  position: relative;
  width: 100%;
  height: calc(100% - 24px);
  border-radius: 0rem 0rem 0rem 0rem !important;
}

.k-iFrameHolder {
  background-color: #dddddd;
  position: absolute;
  width: calc(100% - 100px);
  height: calc(100% - 210px);
  border-radius: 1rem !important;
}

.k-iFrameCenter {
  padding-top: calc(10%);
}

.k-modal {
  --bs-modal-width: 670px;
}

.k-modalHeader {
  position: relative;
  max-width: 360px;
}

.k-modalHeaderImg {
  max-width: 20px;
}

.k-modalHeaderTxt {
  padding-top: 4px;
}

.k-modalTitle {
  font-size: medium;
}

.k-center{
  position: absolute;
  top: 20%;
  left:5%;
  max-width: 340px;
}

.modal-header.k-modalHeader {
  display: flex;
}

.modal.k-m-left .modal-dialog,
	.modal.k-m-right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 360px;
    height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.k-m-left .modal-content,
	.modal.k-m-right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.k-m-left .modal-body,
	.modal.k-m-right .modal-body {
		padding-left: 15px;
    padding-right: 15px;
	}

/*Left*/
	.modal.k-m-left.fade .modal-dialog{
		left: -360px;
		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
	}
	
	.modal.k-m-left.fade.show .modal-dialog{
		left: 0;
	}
        
/*Right*/
	.modal.k-m-right.fade .modal-dialog {
		right: -360px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.k-m-right.fade.show .modal-dialog {
		right: 0;
	}

  .modal-content.k-mc-left,
	.modal-content.k-mc-right {
		border-radius: 0;
		border: none;
	}

	.modal-header {
		border-bottom-color: #EEEEEE;
		background-color: #FAFAFA;
	}

  .k-mf-btn {
    max-height: 20px;
  }

  @keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }

  .animate-flicker {
     animation: flickerAnimation 1s infinite;
 }

 .k-statusImg {
  margin: auto;
  display: block;
 }

 .k-generic-btn-nobg {
  border: none;
  background: none;
 }